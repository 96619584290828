import React from 'react';
import { WidthContainer } from '../..';
import './FarmedFishBenefits.scss';

export interface IFarmedFishBenefitsProps {
    heading: string;
    text: string;
    strapline: string;
    colOneHeading: string;
    colOneText: string;
    colOneImage: {
        altText: string;
        sourceUrl: string;
    }
    colTwoHeading: string;
    colTwoText: string;
    colTwoImage: {
        altText: string;
        sourceUrl: string;
    }
    colThreeHeading: string;
    colThreeText: string;
    colThreeImage: {
        altText: string;
        sourceUrl: string;
    }
}

export const FarmedFishBenefits: React.FC<IFarmedFishBenefitsProps> = (props) => {
    return (
        <div className="FarmedFishBenefits__Outer">
            <WidthContainer>
            <div className="FarmedFishBenefits">
            <img className="FarmedFishBenefits__FishImg" src='/images/farmedfishbenefits-fish.svg' alt='Decorative fish illustration' />


            <h3 dangerouslySetInnerHTML={{ __html: props.heading}}></h3>
            <p dangerouslySetInnerHTML={{ __html: props.text }}></p>
            <h4>{props.strapline}</h4>
            <div className="FarmedFishBenefits__Columns">
            
                <div>
                    <img src={props.colOneImage.sourceUrl} alt={props.colOneImage.altText} />
                    <h5>{props.colOneHeading}</h5>
                    <p dangerouslySetInnerHTML={{ __html: props.colOneText }}></p>
                </div>

                <div>
                    <img src={props.colTwoImage.sourceUrl} alt={props.colTwoImage.altText} />
                    <h5>{props.colTwoHeading}</h5>
                    <p dangerouslySetInnerHTML={{ __html: props.colTwoText }}></p>
                </div>

                <div>
                    <img src={props.colThreeImage.sourceUrl} alt={props.colThreeImage.altText} />
                    <h5>{props.colThreeHeading}</h5>
                    <p dangerouslySetInnerHTML={{ __html: props.colThreeText }}></p>
                </div>
            
            </div>
            <img className="FarmedFishBenefits__JellyImg" src='/images/farm-jellyfish.svg' alt='Decorative fish illustration' />

            </div>
            </WidthContainer>
        </div>
    )
}