import React from 'react';
import { graphql } from 'gatsby';
import { FooterArt, OverflowContainer, SmallKeyArt, WidthContainer, Layout, PageContent, Seo, Aquaculture, Quote, Statistics, WhyAquaculture } from '../components';
import { FarmedFishBenefits } from '../components/SoWhatNow/FarmedFishBenefits/FarmedFishBenefits';
import { SettingTheStandard } from '../components/SoWhatNow/SettingTheStandard/SettingTheStandard';

export default ({ data }) => {
    const fields = data.wpPage.soWhatNowFields;

    return (
        <Layout>
            <Seo title='Wave to Table' description="test" />
            <SmallKeyArt />
            <PageContent>
                <Aquaculture heading={fields.aquacultureHeading} subheading={fields.aquacultureSubheading} />
                {/* <div style={{ overflowX: 'hidden', maxWidth: '100vw' }}> */}
                <WidthContainer>
                  <Quote quote={fields.quoteQuote} author={fields.quoteAuthor} subtext={fields.quoteSubtext} />
                  <Statistics heading={fields.statisticsHeading} stats={fields.statisticsStats} boldText={fields.statisticsBoldText} text={fields.statisticsText} consumptionText={fields.statisticsConsumptionText} />
                  <WhyAquaculture 
                      blockOneHeading={fields.whyAquacultureBlockOneHeading}
                      blockOneText={fields.whyAquacultureBlockOneText}
                      blockThreeHeading={fields.whyAquacultureBlockThreeHeading}
                      blockThreeText={fields.whyAquacultureBlockThreeText}
                      blockTwoHeading={fields.whyAquacultureBlockTwoHeading}
                      blockTwoText={fields.whyAquacultureBlockTwoText}
                      heading={fields.whyAquacultureHeading}
                  />
                </WidthContainer>
                  <FarmedFishBenefits 
                    heading={fields.farmedfishbenefitsHeading} 
                    text={fields.farmedfishbenefitsText} 
                    strapline={fields.farmedfishbenefitsStrapline}
                    colOneHeading={fields.farmedfishbenefitsColoneheading}
                    colOneText={fields.farmedfishbenefitsColonetext}
                    colOneImage={fields.farmedfishbenefitsColoneimage}
                    colTwoHeading={fields.farmedfishbenefitsColtwoheading}
                    colTwoText={fields.farmedfishbenefitsColtwotext}
                    colTwoImage={fields.farmedfishbenefitsColtwoimage}
                    colThreeHeading={fields.farmedfishbenefitsColthreeheading}
                    colThreeText={fields.farmedfishbenefitsColthreetext}
                    colThreeImage={fields.farmedfishbenefitsColthreeimage}
                  />
                  <SettingTheStandard 
                    heading={fields.standardHeading}
                    text={fields.standardText}
                    headingTwo={fields.standardHeadingtwo}
                    textTwo={fields.standardTexttwo}
                    colOneHeading={fields.standardColoneheading}
                    colOneImage={fields.standardColoneimage}
                    colOneButton={fields.standardColonebutton}
                    colTwoHeading={fields.standardColtwoheading}
                    colTwoImage={fields.standardColtwoimage}
                    colTwoButton={fields.standardColtwobutton}
                    colThreeHeading={fields.standardColthreeheading}
                    colThreeImage={fields.standardColthreeimage}
                    colThreeButton={fields.standardColthreebutton}
                    colFourHeading={fields.standardColfourheading}
                    colFourImage={fields.standardColfourimage}
                    colFourButton={fields.standardColfourbutton}
                  />
                {/* </div> */}
            </PageContent>
            <FooterArt />
        </Layout>
    );
};

export const query = graphql`
    query ($id: String!) {
        wpPage(id: { eq: $id }) {
            title
            slug
            id
            soWhatNowFields {
                standardColfourheading
                standardColoneheading
                standardColthreeheading
                standardColtwoheading
                standardHeading
                standardHeadingtwo
                standardText
                standardTexttwo
                standardColfourbutton {
                  target
                  title
                  url
                }
                standardColfourimage {
                  altText
                  sourceUrl
                }
                standardColonebutton {
                  target
                  title
                  url
                }
                standardColoneimage {
                  altText
                  sourceUrl
                }
                standardColthreebutton {
                  target
                  title
                  url
                }
                standardColthreeimage {
                  altText
                  sourceUrl
                }
                standardColtwobutton {
                  target
                  title
                  url
                }
                standardColtwoimage {
                  altText
                  sourceUrl
                }
                farmedfishbenefitsColoneheading
                farmedfishbenefitsColonetext
                farmedfishbenefitsColthreeheading
                farmedfishbenefitsColthreetext
                farmedfishbenefitsColtwoheading
                farmedfishbenefitsColtwotext
                farmedfishbenefitsHeading
                farmedfishbenefitsStrapline
                farmedfishbenefitsText
                farmedfishbenefitsColtwoimage {
                  altText
                  sourceUrl
                }
                farmedfishbenefitsColthreeimage {
                  altText
                  sourceUrl
                }
                farmedfishbenefitsColoneimage {
                  altText
                  sourceUrl
                }
                aquacultureHeading
                aquacultureSubheading
                quoteAuthor
                quoteQuote
                quoteSubtext
                statisticsBoldText
                statisticsConsumptionText
                statisticsHeading
                statisticsStats {
                  text
                  image {
                    altText
                    sourceUrl
                  }
                }
                statisticsText
                whyAquacultureBlockOneHeading
                whyAquacultureBlockOneText
                whyAquacultureBlockThreeHeading
                whyAquacultureBlockThreeText
                whyAquacultureBlockTwoHeading
                whyAquacultureBlockTwoText
                whyAquacultureHeading
            }
        }
    }
`;
