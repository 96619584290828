import React from 'react';
import './SettingTheStandard.scss';
import { WidthContainer } from '../../Global/WidthContainer/WidthContainer';
import { Button } from '../../Global/Button/Button';

export interface ISettingTheStandardProps {
    heading: string;
    headingTwo: string;
    text: string;
    textTwo: string;
    colFourHeading: string;
    colOneHeading: string;
    colThreeHeading: string;
    colTwoHeading: string;
    colFourButton: {
        target: string;
        title: string;
        url: string;
    }
    colFourImage: {
        altText: string;
        sourceUrl: string;
    }
    colOneButton: {
        target: string;
        title: string;
        url: string;
    }
    colOneImage: {
        altText: string;
        sourceUrl: string;
    }
    colThreeButton: {
        target: string;
        title: string;
        url: string;
    }
    colThreeImage: {
        altText: string;
        sourceUrl: string;
    }
    colTwoButton: {
        target: string;
        title: string;
        url: string;
    }
    colTwoImage: {
        altText: string;
        sourceUrl: string;
    }
}

export const SettingTheStandard: React.FC<ISettingTheStandardProps> = (props) => {
    return (
        <div className="SettingTheStandard__Outer">
            <WidthContainer>
                <div className="SettingTheStandard">
                    <img className="SettingTheStandard__FishSide" src="/images/fish-sides.svg" alt="Decorative fish" />

                    <h3 dangerouslySetInnerHTML={{ __html: props.heading }}></h3>
                    <p dangerouslySetInnerHTML={{ __html: props.text }}></p>
                    <h4 dangerouslySetInnerHTML={{ __html: props.headingTwo }}></h4>
                    <p dangerouslySetInnerHTML={{ __html: props.textTwo }}></p>
                    <div className="SettingTheStandard__Columns">
                        <div>
                            <div>
                                <img src={props.colOneImage.sourceUrl} alt={props.colOneImage.altText} />
                                <h5>{props.colOneHeading}</h5>
                            </div>
                            <Button minWidth='0' as='a' href={props.colOneButton.url} target={props.colOneButton.target}>{props.colOneButton.title}</Button>
                        </div>

                        <div>
                            <div>
                                <img src={props.colTwoImage.sourceUrl} alt={props.colTwoImage.altText} />
                                <h5>{props.colTwoHeading}</h5>
                            </div>
                            <Button minWidth='0' as='a' href={props.colTwoButton.url} target={props.colTwoButton.target}>{props.colTwoButton.title}</Button>
                        </div>

                        <div>
                            <div>
                                <img src={props.colThreeImage.sourceUrl} alt={props.colThreeImage.altText} />
                                <h5>{props.colThreeHeading}</h5>
                            </div>
                            <Button minWidth='0' as='a' href={props.colThreeButton.url} target={props.colThreeButton.target}>{props.colThreeButton.title}</Button>
                        </div>

                        <div>
                            <div>
                                <img src={props.colFourImage.sourceUrl} alt={props.colFourImage.altText} />
                                <h5>{props.colFourHeading}</h5>
                            </div>
                            <Button minWidth='0' as='a' href={props.colFourButton.url} target={props.colFourButton.target}>{props.colFourButton.title}</Button>
                        </div>

                    </div>

                </div>
            </WidthContainer>
        </div>
    )
}